import Vue from 'vue'
import Vuex from 'vuex'

import users from './modules/users';
import maps from './modules/maps'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    users,
    maps
  }
})
