import { dataBus } from '@/main'

export default class StudySubmittedDay {

    metadata = [];
    niceDate = null;
    relativeDay = 0;

    constructor(metadata) {
        if (metadata && metadata.length > 0) {
            this.metadata = metadata;
            this.niceDate = this.niceDateString(new Date(metadata[0].submit_date));
            this.relativeDay = metadata[0].relativeDay;
        } else {
            let todayDate = new Date();
            todayDate.setHours(0, 0, 0, 0);
            this.relativeDay = dataBus.relativeDays(todayDate);
            this.niceDate = this.niceDateString(todayDate);
        }
    }

    niceDateString(dt) {
        return dt.toLocaleDateString("en-US");
    }

    addStudies(studies) {
        for (var i = 0; i < studies.length; i++) {
            this.metadata.push(studies[i]);
        }
    }

    updateInclude(updateEvt) {
        for (var i = 0; i < this.metadata.length; i++) {
            if (this.metadata[i].sk == updateEvt.sortkey) {
                // replace the metadata fields
                this.metadata[i].include_study = updateEvt.include_study;
                this.metadata[i].exclude_category = updateEvt.exclude_category;
                this.metadata[i].exclude_note = updateEvt.exclude_note;
            }
        }
    }

}