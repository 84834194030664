// 
// MAP data store
// manages all data and operations on the Ammonia MAP data
// 

import { API } from 'aws-amplify';
import StudySubmittedDay from "@/classes/StudySubmittedDay.js";
import CatHistogram from "@/classes/CatHistogram.js";
import { dataBus } from '@/main'
import StudyMAPmeta from '@/classes/StudyMAPmeta';

function addCategoryFromData(categoryName, absdef, catdef)  {
    const distinctVals = [...new Set(absdef.map(item => item[categoryName]))];
        distinctVals.forEach((item, i) => {
            catdef.push({
                label: item,
                site: 'category',
                class: i,
                histogram: categoryName,
            });
        });
}

const state = {
    histograms_retrieved: false,
    abstracts_retrieved: false,
    metadata_retrieved: false,
    studyMetadata: [],      // 
    studyMetaGroups: [],
    studyAbstracts: [],
    userAbstracts: [],
    abstractHistograms: [],
    studySubmitHistory: [],
    studyMetadataForSite: [], //for selected site on admin tab
    selectedSegments: [],
};

const mutations = {

    'INIT_MAP_DATA'(state) {
        state.metadata_retrieved = false;
        state.abstracts_retrieved = false;
        state.histograms_retrieved = false;
        state.studyMetadata = [];
        state.studyMetaGroups = [];
        state.studyAbstracts = [];
        state.abstractHistograms = [];
        state.userAbstracts = [];
        state.studySubmitHistory = [];
    },

    // store the array of 'StudyMAPmeta' objects 
    'STUDY_METADATA'(state, metaStudies) {
        state.studyMetadata = metaStudies;
        state.metadata_retrieved = true;
    },

    // store the array of 'StudySubmittedDay' objects 
    'STUDY_METADATA_GROUPS'(state, metaGroups) {
        state.studyMetaGroups = metaGroups;
    },

    // store the array of study abstract histogram objects
    // each histogram is an object of type 'CatHistogram'
    'ABSTRACT_HISTOGRAMS'(state, histGroups) {
        state.abstractHistograms = histGroups;
    },

    // store the array of study abstracts
    // each record is the raw data retrieved from dynamoDB
    'STUDY_ABSTRACTS'(state, abstracts) {
        state.studyAbstracts = abstracts;
        state.abstracts_retrieved = true;
    },

    // store the array of abstracts for the user's study site
    // each record is the raw data retrieved from dynamoDB
    'USER_SITE_ABSTRACTS'(state, userAbstracts) {
        state.userAbstracts = userAbstracts;
    },

    // store the array of records for the study submission history
    // there is one record for each study site for each day studies were submitted
    // each record is the raw data retrieved from dynamoDB
    'STUDY_SUBMIT_HISTORY'(state, submitHistory) {
        state.studySubmitHistory = submitHistory;
        state.histograms_retrieved = true;
    },
    // store the array of 'StudyMAPmeta' objects 
    'STUDY_METADATA_SITE'(state, metaStudies) {
        state.studyMetadataForSite = metaStudies;
    },
    // store the array of 'StudyMAPmeta' objects 
    'SELECTED_SEGMENTS'(state, selectedSegments) {
        console.log(selectedSegments);
        state.selectedSegments = selectedSegments;
    },
};

const actions = {

    // called when a user logs out, to clear study data from program memory
    initMAPdata: (context) => {
        context.commit('INIT_MAP_DATA');
    },

    // retrieve the metadata records for the user's study site.
    getStudyMetadata: (context, forceReload) => {

        if (dataBus.currentUser && (forceReload || !context.state.metadata_retrieved)) {
            API.get('mapdata', '/history', {
                queryStringParameters: { site: dataBus.currentUser.siteSlug }
            })
            .then(response => {
                if (response && response.length > 0) {
                    context.dispatch('groupStudies', response);
                }
            })
            .catch(err => {
                this.$store.dispatch("logMAPmessage", {
                    msg: "bad site metadata response",
                    code: 26,
                    data: err,
                  });
            })
        }
    },

    getStudyMetadataForSite: (context, siteName) => {
        context.commit('STUDY_METADATA_SITE',[]);
        API.get('mapdata', '/history', {
            queryStringParameters: { site: siteName }
        })
        .then(response => {
            console.log(response);
            const modifiedResponse = response.map( item => {
                item.filename = item.sk.split("#")[1]; 
                return item;
            });
            context.commit('STUDY_METADATA_SITE',modifiedResponse);
        })
        .catch(err => {
            this.$store.dispatch("logMAPmessage", {
                msg: "bad site metadata response",
                code: 26,
                data: err,
                });
        })
    },

    // get the abstracted data from each study (used for the graphs on the 'view results' page)
    getStudyAbstracts: (context) => {
        if (dataBus.currentUser) {
            let qsp = 'none';
            if (dataBus.currentUser.permissions.viewAllData == 'y') {
                qsp = 'all';
            }
            else if (dataBus.currentUser.permissions.viewSiteData == 'y') {
                qsp = dataBus.currentUser.siteSlug;
            }
    
            if (qsp != 'none' && !context.state.abstracts_retrieved) {
                API.get('mapresults', '/abstract', {
                    queryStringParameters: { site: qsp }
                })
                .then(response => {
                    
        
                    // extract the actual abstracts
                    let absdef = response.filter(abst => {
                        let tx = abst.sk.split('#');
                        return (tx[0] == 'parsed') //was 'study'
                    })
                    context.commit('STUDY_ABSTRACTS', absdef);

                    // get distinct sites from data
                    let catdef = [];
                    addCategoryFromData( 'site', absdef, catdef);
                    addCategoryFromData( 'gender', absdef, catdef);
                    // addCategoryFromData( 'agent', absdef, catdef);
                    // addCategoryFromData( 'scanner', absdef, catdef);

                    // extract the category definitions
                    const catFromDB = response.filter(abst => {
                        let tx = abst.sk.split('#');
                        return (tx[0] == 'category') 
                    });
                    catdef = catdef.concat(catFromDB.sort(
                        (itemA, itemB) =>
                            itemA.histogram.localeCompare(itemB.histogram) ||
                            itemA.class - itemB.class));

                    // extract the abstracts for the user's study site
                    let sitedef = response.filter(abst => {
                        let tx = abst.sk.split('#');
                        return (tx[0] == 'study' && abst.site == dataBus.currentUser.siteSlug);
                    })
                    // store the abstract data for the user's study site
                    context.commit('USER_SITE_ABSTRACTS', sitedef);
        
                    // histogram the abstract data
                    context.dispatch('histogramAbstractData', { cat: catdef, abstracts: absdef });
                })
                .catch(err => {
                    this.$store.dispatch("logMAPmessage", {
                        msg: "bad site abstract response",
                        code: 27,
                        data: err,
                      });
                })
            }
        }
    },

    addCategoryFromData: (categoryName, absdef, catdef) => {
        const distinctVals = [...new Set(absdef.map(item => item[categoryName]))];
            distinctVals.forEach((item, i) => {
                catdef.push({
                    label: item,
                    site: 'category',
                    class: i,
                    histogram: categoryName,
                });
            });
    },
    // create and populate the histograms for each abstracted data quantity
    // => creates histogram objects for BMI, age, gender, stress agent, scanner type
    // => populates each histogram with the set of study data abstracts
    histogramAbstractData: (context, params) => {
        var hx = [];
        var histGroups = [];
        var k = 0;
        params.cat.forEach((cat) => {
            k = hx.indexOf(cat.histogram);
            if (k == -1) {
                hx.push(cat.histogram);
                histGroups.push(
                    new CatHistogram(cat, hx.length - 1)
                );
            } else {
                histGroups[k].addBar(cat);
            }
        });
        histGroups.forEach((group) => {
            group.sortStudies(params.abstracts, false);
        });
        context.commit('ABSTRACT_HISTOGRAMS', histGroups);
    },

    // retrieve number studies submitted vs date for each study site
    // displayed in the histograms on the 'overview page'
    getSubmitHistory: (context) => {

        if (dataBus.currentUser) {
            let qsp = 'none';
            if (dataBus.currentUser.permissions.viewAllData == 'y') {
                qsp = 'all';
            }
            else if (dataBus.currentUser.permissions.viewSiteData == 'y') {
                qsp = dataBus.currentUser.siteSlug;
            }
    
            if (qsp != 'none' && !context.state.histograms_retrieved) {
                API.get('mapresults', '/history', {
                    queryStringParameters: { site: qsp }
                })
                .then(response => {
                    context.commit('STUDY_SUBMIT_HISTORY', response)
                })
                .catch(err => {
                    this.$store.dispatch("logMAPmessage", {
                        msg: "bad site histogram response",
                        code: 28,
                        data: err,
                      });
                })
            }
        }
    },

    // group the study metadata records by submission date.
    // for each date that studies were created, there is one object of type 'StudySubmittedDay' created.
    // Each 'StudySubmittedDay' object contains an array of 'StudyMAPmeta' objects.
    // The 'StudyMAPmeta' object contains the metadata for a single study, and the methods to operate on it.
    groupStudies: (context, studies) => {
        // order the records by the study submission date
        var orderedStudies = studies.sort(function (a, b) {
            return b.submit_date - a.submit_date;
        });

        // calculate the relative day for each study
        orderedStudies.forEach((study) => {
            if (study.submit_date && typeof study.submit_date === 'number') {
                study.relativeDay = dataBus.relativeDays(new Date(study.submit_date));
            } else {
                study.relativeDay = 0
            }
        });

        // create a 'StudyMAPmeta' object for each metadata record
        let metaStudies = [];
        orderedStudies.forEach((study) => {
            metaStudies.push(new StudyMAPmeta(dataBus.currentUser, 'dummy', true, study));
        });
        context.commit('STUDY_METADATA', metaStudies);

        // group the 'StudyMAPmeta' objects by submission date
        var studyDay = [];      
        var groupedStudies = [];
        studyDay.push(metaStudies[0]);
        for (var i = 1; i < orderedStudies.length; i++) {
            if (orderedStudies[i].relativeDay == orderedStudies[i - 1].relativeDay) {
                studyDay.push(metaStudies[i]);
            }
            else {
                groupedStudies.push(new StudySubmittedDay(studyDay));
                studyDay = [];
                studyDay.push(metaStudies[i]);
            }
        }
        if (studyDay.length > 0) {
            groupedStudies.push(new StudySubmittedDay(studyDay));
        }
        context.commit('STUDY_METADATA_GROUPS', groupedStudies);
        orderedStudies = [];
        groupedStudies = [];
    },
    setSelectedSegments: (context, segments) => {
        context.commit('SELECTED_SEGMENTS', segments);
    }

};

const getters = {

    // return the array of records for the study submission history
    // there is one record for each study site for each day studies were submitted
    // each record is the raw data retrieved from dynamoDB
    studySubmitHistory: (state) => {
        return state.studySubmitHistory;
    },

    // return the array of 'StudySubmittedDay' objects 
    currentStudyMetadata: (state) => {
        return state.studyMetaGroups;
    },

    // return the array of study abstract histogram objects
    // each histogram is an object of type 'CatHistogram'
    currentAbstractHistograms: (state) => {
        return state.abstractHistograms;
    },

    // return the array of study abstracts
    // each record is the raw data retrieved from dynamoDB 
    currentStudyAbstracts: (state) => {
        return state.studyAbstracts;
    },

    // return the array of abstracts for the user's study site
    // each record is the raw data retrieved from dynamoDB
    currentUserAbstracts: (state) => {
        return state.userAbstracts;
    },
    // return the array of 'StudySubmittedDay' objects 
    studyMetadataForSite: (state) => {
        return state.studyMetadataForSite;
    },

    abstractsRetrieved: (state) => {
        return state.abstracts_retrieved;
    },
    selectedSegments:  (state) => {
        return state.selectedSegments;
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};