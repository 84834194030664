// class that contains all of the attributes needed to write the study metadata record into the dynamo database
//
import API from "@aws-amplify/api";

export default class StudyMAPmeta {

    user = null;
    goodmeta = false;
    metatype = "default";

    //
    // parameters that are elements of the study metadata record
    //  
    siteSlug = "none";      // used for the partition key
    filename = "none";      // used to construct the sort key
    software = "none";
    studyLabel = "none";    // e.g. IND, test, post-IND
    stage = "new";

    submit_user = "none";
    submit_date = 0;

    update_meta_user = "none";
    update_data_user = "none";
    meta_updates = 0;
    data_updates = 0;
    update_meta_date = 0;
    update_data_date = 0;
    
    include_study = true;
    exclude_category = "none";
    exclude_note = "none";

    covid_status = "covid not disclosed"

    /*      metadata contents:

        siteSlug        // defines the partition key
        filename        study name  // used to define the sort key
        software
        studyLabel
        stage

        submit_user         name of the user that originally submitted the study
        submit_date         the original submit date

        update_meta_user        name of the person who made the most recent metadata update
        update_data_user        name of the person who made the most recent study data update
        meta_updates            number of times that the study metadata is updated
        data_updates            number of times that the study data is updated
        update_meta_date        date of the most recent metadata update
        update_data_date        date of the most recent study data update

        include_study
        exclude_category
        exclude_note

        covid_status
    */

    // the constructor is called from:
    //      1 - the 'maps.js' store after the metadata records from stored studies are read from the database.
    //              a - the 'maps.js' 'getStudyMetadata' method is called from 'SubmitStudy.vue' when the user navigates
    //                  to the 'submit studies' page. A 'StudyMAPmeta' object is created for each metadata record.
    //              b - the metadata records are grouped by study submission date, each group is used to create a
    //                  'StudySubmittedDay' object. 
    //              c - The 'StudySubmittedDay' object is passed by 'SubmitStudy.vue' as a prop to 'StoredStudy.vue'.
    //              d - each 'StudyMAPmeta' object contained within 'StudySubmittedDay' is passed as a prop to 'SubmittedStudy.vue'.
    //                  'SubmittedStudy.vue' provides the means for the user to change the editable parameters of the metadata record.
    //              component hierarchy:  SubmitStudy -> StoredStudy -> SubmittedStudy
    //
    //      2 - 'StudyMAPfile.js' after a study is dropped in the drop box.
    //              a - When a file is dropped into the 'SubmitStudy.vue' drop box, a 'StudyMAPfile' object is created.
    //              b - Each 'StudyMAPfile' object (passed as a prop) is rendered by 'XMLstudyCard.vue'.
    //              c - 'XMLstudyCard.vue' reads the study file contents and invokes 'StudyMAPfile' method 'addFileContents'.
    //              d - 'addFileContents' invokes method 'checkDB'.
    //              e - 'checkDB' queries the database to determine if the study has already been stored.
    //              f - if the study is already stored, 'checkDB' creates a new 'StudyMAPmeta' object.
    //
    constructor(user, filename, hasmeta, metarecord) {
        this.user = user;

        if (hasmeta) {
            this.setMetaFromDB(metarecord)
        }
        else {
            this.siteSlug = user.siteSlug;
            this.filename = filename;
            this.software = user.software;
            this.studyLabel = user.studyLabel;
        }
    }

    // set the metadata parameters from a record read from the database
    // perform validation that each parameter exists
    setMetaFromDB (metarecord) {
        this.goodmeta = true;

        if (metarecord.pk) {
            this.siteSlug = metarecord.pk;
        }
        else {
            this.goodmeta = false;
        }
        if (metarecord.sk) {
            this.filename = metarecord.sk.split("#")[1];
        }
        else {
            this.goodmeta = false;
        }
        if (metarecord.software) {
            this.software = metarecord.software;
        }
        else {
            this.goodmeta = false;
        } 
        if (metarecord.studyLabel) { 
            this.studyLabel = metarecord.studyLabel;
        }
        else {
            this.goodmeta = false;
        }
        if (metarecord.stage) { 
            this.stage = metarecord.stage;
        }
        else {
            this.goodmeta = false;
        }

        if (metarecord.submit_user) { 
            this.submit_user = metarecord.submit_user;
        }
        else {
            this.goodmeta = false;
        }
        if (metarecord.submit_date) { 
            this.submit_date = metarecord.submit_date;
        }
        else {
            this.goodmeta = false;
        }

        if (metarecord.update_meta_user) { 
            this.update_meta_user = metarecord.update_meta_user;
        }
        else {
            this.goodmeta = false;
        }

        if (metarecord.update_data_user) { 
            this.update_data_user = metarecord.update_data_user;
        }
        else {
            this.goodmeta = false;
        }
        if (metarecord.meta_updates) { 
            this.meta_updates = metarecord.meta_updates;
        }
        else {
            this.goodmeta = false;
        }
        if (metarecord.data_updates) { 
            this.data_updates = metarecord.data_updates;
        }
        else {
            this.goodmeta = false;
        }
        if (metarecord.update_meta_date) { 
            this.update_meta_date = metarecord.update_meta_date;
        }
        else {
            this.goodmeta = false;
        }
        if (metarecord.update_data_date) { 
            this.update_data_date = metarecord.update_data_date;
        }
        else {
            this.goodmeta = false;
        }

        if (metarecord.include_study) { 
            this.include_study = metarecord.include_study;
        }
        else {
            this.goodmeta = false;
        }
        if (metarecord.exclude_category) {
            this.exclude_category = metarecord.exclude_category;
        }
        else {
            this.goodmeta = false;
        }
        if (metarecord.exclude_note) { 
            this.exclude_note = metarecord.exclude_note;
        }
        else {
            this.goodmeta = false;
        }

        if (metarecord.covid_status) { 
            this.covid_status = metarecord.covid_status;
        }
        else {
            this.goodmeta = false;
        }

        if (this.goodmeta) {
            this.metatype = "existing study"    // the metadata record is from a study stored in the database
        }
    }

    // return an object containing the metadata parameters that are user-editable
    // this method is called from 'SubmittedStudy.vue'
    // when the user selects a study that has already been stored in the database, 
    // and wishes to change one of the editable parameters (covid status, study inclusion)
    getEditableParams () {
        return {
            include_study: this.include_study,
            exclude_category: this.exclude_category,
            exclude_note: this.exclude_note,
            covid_status: this.covid_status
        }
    }

    // update the editable metadata parameters 
    // this method is called from 'SubmittedStudy.vue' 
    // only the metadata record - for a study already stored in the database - is sent
    putEditableParams (newParams) {

        let sameParams = this.include_study == newParams.include_study;
        sameParams = sameParams && this.exclude_category == newParams.exclude_category;
        sameParams = sameParams && this.exclude_note == newParams.exclude_note;
        sameParams = sameParams && this.covid_status == newParams.covid_status;

         // if the parameters have changed, update the record in the database
        if (!sameParams) {
            this.include_study = newParams.include_study;
            this.exclude_note = newParams.exclude_note;
            this.exclude_category = newParams.exclude_category;
            this.covid_status = newParams.covid_status;
            this.writeMAPmeta('update meta');
        }
    }

    // there are 3 instances when the metadata record is written to the database:
    //      1 - A new study is submitted
    //      2 - The data from a stored study is updated
    //      3 - The user updates editable metadata information (covid status, study inclusion)
    writeMAPmeta(dataUpdate) {

        return new Promise((resolve, reject) => {
            let updateDate = new Date();

            if (dataUpdate == 'new study') {
                this.submit_user = this.user.fullname;
                this.submit_date = updateDate.getTime();
                this.stage = 'new';
            }
            else if (dataUpdate == 'update study') {
                this.data_updates = this.data_updates + 1;
                this.update_data_user = this.user.fullname;
                this.update_data_date = updateDate.getTime();
                this.meta_updates = this.meta_updates + 1;
                this.update_meta_user = this.user.fullname;
                this.update_meta_date = updateDate.getTime();
                this.stage = 'new';
            }
            else if (dataUpdate == 'update meta') {
                this.meta_updates = this.meta_updates + 1;
                this.update_meta_user = this.user.fullname;
                this.update_meta_date = updateDate.getTime();
            }

            let meta_body = {
                'siteSlug': this.siteSlug,      // defines the partition key
                'filename': this.filename,      // used to define the sort key
                'software': this.software,
                'studyLabel': this.studyLabel,
                'stage': this.stage,
    
                'submit_user': this.submit_user,
                'submit_date': this.submit_date,
    
                'meta_updates': this.meta_updates,
                'update_meta_user': this.update_meta_user,
                'update_meta_date': this.update_meta_date,
    
                'data_updates': this.data_updates,
                'update_data_user': this.update_data_user,
                'update_data_date': this.update_data_date,
    
                'include_study': this.include_study,
                'exclude_category': this.exclude_category,
                'exclude_note': this.exclude_note,

                'covid_status': this.covid_status
            }

            API.post('mapdata', '/meta', {body: meta_body})
            .then ( response => {
                resolve( {
                    code: 1,
                    message: "study metadata updated in DB",
                    retrec: response
                })
            })
            .catch( err => {
                reject( {
                    code: 0,
                    message: 'bad metadata update response from DB', 
                    retrec: err
                })
            })
        })
    }

}