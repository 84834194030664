<template>
  <v-app-bar color="#8CC342">
    <v-toolbar-title class="text-h4 white--text">Ammonia MAP</v-toolbar-title>
    <v-spacer></v-spacer>
    <div v-if="user">
      <!-- <v-btn
        :plain="!isSelectedRoute('Overview')"
        :color="colorName('Overview')"
        elevation="0"
        v-if="user && user.canViewHistograms"
        @click="changePage('Overview')"
      >Overview</v-btn>
      | -->
      <v-btn
        v-if="user && user.userCanSubmit"
        :plain="!isSelectedRoute('SubmitStudy')"
        :color="colorName('SubmitStudy')"
        elevation="0"
        @click="changePage('SubmitStudy')"
      >Submit Study</v-btn>
      |
      <v-btn
        v-if="user && user.canViewData"
        :plain="!isSelectedRoute('Dashboard')"
        :color="colorName('Dashboard')"
        elevation="0"
        @click="changePage('Dashboard')"
      >View Results</v-btn>
      |
      <v-btn
        v-if="user && user.canViewData"
        :plain="!isSelectedRoute('Admin')"
        :color="colorName('Admin')"
        elevation="0"
        @click="changePage('Admin')"
      >Administration</v-btn>
      |
      <v-btn
        plain
        elevation="0"
        @click="logout"
      >Logout</v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { Auth, Hub } from "aws-amplify";
import { dataBus } from "@/main";

export default {
  name: "Navbar",
  data() {
    return {};
  },
  methods: {
    async logout() {
      try {
        await Auth.signOut({ global: true });
        this.$store.dispatch("signoutUser");
      } catch (error) {
        this.$store.dispatch("logMAPmessage", {
          msg: "error signing out",
          code: 13,
          data: error,
        });
      }
    },
    changePage(routeName) {
      dataBus.changeRoute(routeName);
    },
    colorName(routeName) {
      return routeName === this.selectedRoute ? 'primary' : '';
    },
    isSelectedRoute(routeName) {
      return routeName === this.selectedRoute;
    },
  },
  created() {},
  beforeDestroy() {},
  mounted() {
    Hub.listen("auth", (data) => {
      switch (data.payload.event) {
        case "signIn":
          this.$store.dispatch("signinUser", data.payload.data.attributes);
          break;
        case "signUp":
          this.$store.dispatch("logMAPmessage", {
            message: "user signed up",
            code: 14,
            data: data.payload,
          });
          break;
        case "signOut":
          //this.$store.dispatch("signoutUser");
          break;
        case "signIn_failure":
          this.$store.dispatch("logMAPmessage", {
            msg: "user signin failed",
            code: 15,
            data: data.payload,
          });
          break;
        case "configured":
          this.$store.dispatch("logMAPmessage", {
            msg: "the Auth module is configured",
            code: -16,
            data: data.payload,
          });
      }
    });

    Auth.currentAuthenticatedUser()
      .then(() => {
        if (!this.user) {
          this.logout();
        }
      })
      .catch((err) => {
        if (err) {
          this.$store.dispatch("logMAPmessage", {
            msg: "auth error",
            code: -17,
            data: err,
          });
        } else {
          dataBus.changeRoute("Signup");
        }
      });
  },
  computed: {
    user() {
      return this.$store.getters.currentUser;
    },
    selectedRoute() {
      return this.$route.name;
    }
  },
};
</script>

<style scoped>
.button-tx {
  background-color: transparent;
}
</style>