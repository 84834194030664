<template>
  <div id="app">
    <v-app>
      <div>
        <Navbar />
        <h1 v-if="bannerMessage"> {{ bannerMessage }} </h1>
        <router-view />
      </div>
    </v-app>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "App",
  components: {
    Navbar,
  },
  computed: {
    bannerMessage() {
      return this.$store.getters.bannerMessage;
    },
  },
};
</script>
