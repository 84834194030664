import Vue from 'vue'
import VueRouter from 'vue-router'
import Signup from '@/components/Signup.vue'

import { Auth } from "aws-amplify";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Signup',
    component: Signup
  },
  {
    path: '/overview',
    name: 'Overview',
    component: () => import('../views/Overview.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/submit',
    name: 'SubmitStudy',
    component: () => import('../views/SubmitStudy.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  try {
    if (to.matched.some(rec => rec.meta.requiresAuth)) {
      Auth.currentAuthenticatedUser().then( resp => {
        let user = resp.attributes.email
          if (user) {
            next()    // user is signed in, go to the requested route
          }
          else {
            next({ name: 'Signup' })  // redirect to the signup/login page
          }
      }).catch (autherr => {
          this.$store.dispatch("logMAPmessage", {
            msg: "auth error",
            code: 35,
            data: autherr,
          });
          next({ name: 'Signup' })  // redirect to the signup/login page
      })
    }
    else {
      next();   // the route is not guarded, go to the requested route
    }
  }
  catch (naverr) {
    print('navigation error = ', naverr)
  }
})

export default router
