/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:d9900252-77fa-4ede-9ea8-3ea793fc6427",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_RWpIdly56",
    "aws_user_pools_web_client_id": "6r2ksgvt07c4mas50k212vfkth",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "EMAIL"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "mapuser-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "mapresults-prod",
            "region": "us-east-2"
        },
        {
            "tableName": "mapdata-prod",
            "region": "us-east-2"
        }
    ],
    "aws_user_files_s3_bucket": "s3studybackup202000-prod",
    "aws_user_files_s3_bucket_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "mapuser",
            "endpoint": "https://1wz96rovjk.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "mapresults",
            "endpoint": "https://yp2ank0bwf.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        },
        {
            "name": "mapdata",
            "endpoint": "https://49un9gmszf.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
