import Vue from 'vue'
import App from './App.vue'
import router from './router/router-index'
import store from './store/store-index'
import vuetify from './plugins/vuetify';

import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';
import awsmobile from './aws-exports';

Amplify.configure(awsmobile);

Vue.config.productionTip = false

// create a Vue instance as an event bus
export const dataBus = new Vue({
  router,
  data() {
    return {
      startParams: { year: 2020, month: 8, day: 1 },  // arbitrary trial start date of 1-Sept-2020
      startDate: null,
      currentUser: null,
      bannerMessage: null
    }
  },
  store,
  methods: {
    changeRoute(routeName) {
      // console.log('changing route from = ', this.$route.name)
        if (routeName != this.$route.name) {
          this.$router.push({ name: routeName });
        }
    },

    userLoggedIn (newuser) {
        this.currentUser = newuser;
        if (newuser.userCanSubmit) {
          this.changeRoute('SubmitStudy')
        } else if (newuser.canViewData) {
          this.changeRoute('Dashboard')
        }
    },

    userLoggedOut () {
      this.currentUser = null;
      this.$store.dispatch("initMAPdata");
      this.changeRoute('Signup')
    },

    relativeDays(dt) {
      let rd = 0;
      if (dt) {
        rd = dt.getTime() - this.startDate.getTime();
        rd = rd / (24 * 3600 * 1000);
        rd = Math.floor(rd);
      }
      return (rd);
    }

  },
  created() {
    this.startDate = new Date();
    this.startDate.setFullYear(this.startParams.year, this.startParams.month, this.startParams.day);
    this.startDate.setHours(0, 0, 0, 0);
    this.currentUser = null;
  },

});

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
