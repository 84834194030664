
export default class CatHistogram {

    histname = "none";
    hkey = 0;
    id = "none";
    bars = [];
    totalStudies = 0;
    filteredStudies = 0;

    constructor(histrec, hkey) {
        this.histname = histrec.histogram;
        this.hkey = hkey;
        this.id = 'qxb' + histrec.histogram + hkey.toString();
        this.bars.push({
            class: histrec.class,
            label: histrec.label,
            amplitude: 0,
            filteredQuant: 0,
            includeStudy: true,           // true means that the abstracts are NOT filtered out
            sort_min: histrec.sort_min,
            sort_max: histrec.sort_max
        });
    }

    // add the definition of a histogram bar
    // the argument record contains:
    //      class       = numeric order that the bar should appear in (class = 0 for top)
    //      histogram   = name of the category (e.g. age, BMI)
    //      label       = label for the histogram bar (e.g. 'male', 'female')
    //      pk
    //      sk
    //      sort_max
    //      sort_min
    addBar(histrec) {
        if (histrec.histogram == this.histname) {
            this.bars.push({
                class: histrec.class,
                label: histrec.label,
                amplitude: 0,
                filteredQuant: 0,
                includeStudy: true,
                sort_min: histrec.sort_min,
                sort_max: histrec.sort_max
            });
        }
        else {
            this.$store.dispatch('logMAPmessage', { msg: 'error in category sort', data: "none", code: 44 });
        }
    }

    // sort the array of abstracts into the histogram categories
    // TODO:  ugh, there must be a more graceful way to do this!
    sortStudies(abstracts, filtered) {
        if (filtered) {
            this.filteredStudies = 0;
            for( let bar of this.bars ) {
                bar.filteredQuant = 0;
            }
        }
        else {
            this.totalStudies = 0;
            for( let bar of this.bars ) {
                bar.amplitude = 0;
            }
        }
        for (let abstract of abstracts) {
            if (this.histname == 'BMI') {
                this.sortNumeric(abstract.BMI, filtered);
            }
            else if (this.histname == 'age') {
                this.sortNumeric(abstract.age, filtered);
            }
            else {
                this.sortCategory(abstract[this.histname], filtered);
            }
        }
    }

    findNumericBar(dn) {
        for (var i = 0; i < this.bars.length; i++) {
            if (dn >= this.bars[i].sort_min && dn < this.bars[i].sort_max) {
                return i;
            }
        }
        return -1;
    }

    findCategoryBar(ds) {
        for (var i = 0; i < this.bars.length; i++) {
            if (ds == this.bars[i].label) {
                return i;
            }
        }
        return -1;
    }

    sortNumeric(dn, filtered) {
        let i = this.findNumericBar(dn);
        if (i >= 0) {
            this.bars[i].filteredQuant += 1;
            this.filteredStudies += 1;
            if (!filtered) {
                this.bars[i].amplitude += 1;
                this.totalStudies += 1;
            }
        }
    }

    sortCategory(ds, filtered) {
        let i = this.findCategoryBar(ds);
        if (i >= 0) {
            this.bars[i].filteredQuant += 1;
            this.filteredStudies += 1;
            if (!filtered) {
                this.bars[i].amplitude += 1;
                this.totalStudies += 1;
            }
        }
    }

    // returns true if the study abstract satisfies the filter criteria for this histogram
    applyFilter(abstract) {
        var k = 0;
        if (this.histname == 'BMI') {
            k = this.findNumericBar(abstract.BMI);
        }
        else if (this.histname == 'age') {
            k = this.findNumericBar(abstract.age);
        }
        else {
            k = this.findCategoryBar(abstract[this.histname]);
        }
        if (k >= 0) {
            return this.bars[k].includeStudy;
        }
        else
            return false;
    }
}