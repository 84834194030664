export default class Mapuser {

    validUser = false;
    institution = null;
    fullname = null;
    permissions = null;
    siteSlug = null;
    email = null;
    software = null;
    status = "inactive";
    studyLabel = null;
    studySite = null;
    userGroup = null;
    userSlug = null;

    // derived parameters
    userCanSubmit = false;
    canViewData = false;
    canViewHistograms = false;

    constructor(userrec) {

        if (userrec) {
            if (userrec.institution) this.institution = userrec.institution;
            if (userrec.name) this.fullname = userrec.name;
            if (userrec.siteSlug) this.siteSlug = userrec.siteSlug;
            if (userrec.sk) this.email = userrec.sk;    // the sort key is the user's email
            if (userrec.software) this.software = userrec.software;
            if (userrec.status) this.status = userrec.status;
            if (userrec.studyLabel) this.studyLabel = userrec.studyLabel;
            if (userrec.studySite) this.studySite = userrec.studySite;
            if (userrec.userGroup) this.userGroup = userrec.userGroup;
            if (userrec.userSlug) this.userSlug = userrec.userSlug;
            if (userrec.permissions) this.permissions = userrec.permissions;
            this.validUser = this.validateUser();
        }

    }

    validateUser () {
        var gooduser = false;
        if (this.status == 'active') {
            gooduser = true;
            gooduser = gooduser && this.institution && this.userGroup;
            gooduser = gooduser && this.fullname && this.userSlug && this.email;
            gooduser = gooduser && this.siteSlug && this.studySite;
            gooduser = gooduser && this.studyLabel && this.software;
            gooduser = gooduser && this.validPermissions()
        }
        return gooduser;
    }

    validPermissions () {
        
        if (!this.permissions) {
            return false;
        }

        let goodpermissions = true;

        // permissions to submit and/or update study data files
        if (this.permissions.submitStudy && this.permissions.updateStudy) {
            goodpermissions = goodpermissions && (this.permissions.submitStudy == 'y' || this.permissions.submitStudy == 'n');
            goodpermissions = goodpermissions && (this.permissions.updateStudy == 'y' || this.permissions.updateStudy == 'n');
            this.userCanSubmit = (this.permissions.submitStudy == 'y' || this.permissions.updateStudy == 'y');
        }
        else {
            goodpermissions = false;
        }

        // view submission statistics (submissions per site per day histograms)  
        if (this.permissions.viewSummaryData) {
            goodpermissions = goodpermissions &&  
            (this.permissions.viewSummaryData == 'y' || this.permissions.viewSummaryData == 'n');
            this.canViewHistograms = this.permissions.viewSummaryData == 'y'
        }
        else {
            goodpermissions = false;
        }

        // view study data (on the "view results" dashboard)
        if (this.permissions.viewSiteData && this.permissions.viewAllData) {
            goodpermissions = goodpermissions &&  
                (this.permissions.viewSiteData == 'y' || this.permissions.viewSiteData == 'n');
            goodpermissions = goodpermissions && 
                (this.permissions.viewAllData == 'y' || this.permissions.viewAllData == 'n');
            this.canViewData = (this.permissions.viewAllData == 'y' || this.permissions.viewSiteData == 'y');
        }
        else {
            goodpermissions = false;
        }

        // download study data (TODO: not implemented yet)
        goodpermissions = goodpermissions && this.permissions.downloadSiteData && 
            (this.permissions.downloadSiteData == 'y' || this.permissions.downloadSiteData == 'n');
        goodpermissions = goodpermissions && this.permissions.downloadAllData && 
            (this.permissions.downloadAllData == 'y' || this.permissions.downloadAllData == 'n');

        // view application access statistics (TODO: not implemented yet)
        goodpermissions = goodpermissions && this.permissions.accessLog && 
            (this.permissions.accessLog == 'y' || this.permissions.accessLog == 'n');


        return goodpermissions;
    }

}