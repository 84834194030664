// 
// user data store
// manages all data and operations on the user's record
// 

import { dataBus } from '@/main'
import Mapuser from "@/classes/Mapuser.js";
import { API } from 'aws-amplify';

const state = {
    currentUser: null,
    bannerMessage: null,
    consoleMessage: null,
    sites: []
};

const mutations = {
    'CURRENT_USER'(state, user) {
        state.currentUser = user;
    },
    'USER_BANNERMESSAGE'(state, msg) {
        state.bannerMessage = msg;
    },
    'LAST_MESSAGE'(state, msg) {
        state.consoleMessage = msg;
    },
    'SET_SITES'(state, sites) {
        state.sites = sites;
    },
};

const actions = {

    // called from the signin page, when a user has authenticated through Amplify Auth
    signinUser: (context, user) => {
        API.get('mapuser', '/user', {
            queryStringParameters: { user: user.email }
        })
        .then(response => {
            let newuser = new Mapuser(response);
            if (newuser.validUser) {
                context.commit('CURRENT_USER', newuser);
                dataBus.userLoggedIn (newuser);
                context.dispatch('logMAPmessage', {msg: 'user logged in', code: 52, 
                data: {
                    user: newuser.fullname,
                    site: newuser.studySite
                }})
            }
            else {
                context.commit('USER_BANNERMESSAGE', 'user account is not active');
                context.dispatch('signoutUser', {
                    fullname: user.email,
                    site: 'unknown'
                });
            }
        })
        .catch(err => {
            context.dispatch('logMAPmessage', {msg: 'bad user response', code: 51, data: err})
        })
    },

    signoutUser: (context) => {
        let fullname = (context.state.currentUser && context.state.currentUser.fullname) ? context.state.currentUser.fullname : 'unknown' ;
        let site = (context.state.currentUser && context.state.currentUser.studySite) ? context.state.currentUser.studySite : 'unknown' ;
        context.commit('CURRENT_USER', null);
        context.dispatch("logMAPmessage", {
            msg: "user signed out",
            code: 18,
            data: {
              user: fullname,
              site: site,
            },
          });
        dataBus.userLoggedOut();
    },

    // temporary method to concentrate console.log messages in one place.
    // TODO: submit the log message to the database
    // 'message' is an object with 3 components
    //      msg:    a text message
    //      code:   a unique integer code
    //      data:   an object containing any other related information
    logMAPmessage: (context, message) => {
        console.log('message = ', message);
        context.commit('LAST_MESSAGE', message);
        // if the message code is greater than 100, log the message to dynamodb
        // TODO:  log study submitted, study updated, meta updated
        // TODO:  append date to each log message
    },

    querySites: (context, ) => {
        API.get('mapuser', '/user', {
            queryStringParameters: { site: true }
        })
        .then(response => {
            const siteNames = response.map(item => item.siteSlug);
            context.commit('SET_SITES', siteNames);
        })
        .catch(err => {
            console.error(err);
        })
    },
};

const getters = {
    // return the record of the currently logged-in user
    currentUser: (state) => {
        return state.currentUser;
    },
    bannerMessage: (state) => {
        return state.bannerMessage;
    },
    sites: (state) => {
        return state.sites;
    },
};

export default {
    state,
    mutations,
    actions,
    getters
};